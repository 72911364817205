.mainBox[data-v-824ab6d4] {
  width: 100%;
  height: 100%;
}
.tabsBox[data-v-824ab6d4] {
  background-color: #FFFFFF;
  padding: 10px 12px;
  width: calc(100% - 24px);
  height: calc(100% - 65px);
}
[data-v-824ab6d4] .el-tabs__content {
  background-color: transparent;
}